<template>
  <div class="account">
    <Loader v-if="isLoading" />
    <div v-else-if="data">
      <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="7">
          <vs-card>
            <div slot="header">
              <h3>{{ $l.get("account.general-header") }}</h3>
            </div>
            <div>
              <form @submit.prevent="onAccountSubmit">
                <vs-alert :active="isAccountUpdateSuccess" color="success"
                  >{{ $l.get("dictionary.success") }}!</vs-alert
                >
                <vs-input
                  v-model="fullName"
                  :danger="isNameInvalid"
                  :danger-text="$l.get('errors.input-required', 'capitalize')"
                  :label-placeholder="
                    $l.get('dictionary.full-name', 'capitalize')
                  "
                />
                <p style="margin: 5px; margin-top: 15px; color: #BBB">
                  {{ $l.get("account.language") }}
                </p>
                <ul class="centerx">
                  <li>
                    <vs-radio
                      v-model="language"
                      vs-name="language"
                      vs-value="en-US"
                      >{{ $l.get("dictionary.languages.english") }}</vs-radio
                    >
                  </li>
                  <li>
                    <vs-radio
                      v-model="language"
                      vs-name="language"
                      vs-value="pt-BR"
                      >{{ $l.get("dictionary.languages.portuguese") }}</vs-radio
                    >
                  </li>
                  <li>
                    <vs-radio
                      v-model="language"
                      vs-name="language"
                      vs-value="es-ES"
                      >{{ $l.get("dictionary.languages.spanish") }}</vs-radio
                    >
                  </li>
                </ul>
                <input
                  type="submit"
                  style="position: absolute; left: -9999px; width: 1px; height: 1px;"
                  tabindex="-1"
                />
              </form>
              <div
                style="margin-top: 25px; margin-bottom: 10px; text-align: right"
              >
                <vs-button
                  color="primary"
                  type="filled"
                  @click="onAccountSubmit()"
                  >{{ $l.get("dictionary.submit") }}</vs-button
                >
              </div>
            </div>
          </vs-card>
        </vs-col>
      </vs-row>
      <vs-row vs-justify="center" v-if="isMainProfile">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="7">
          <vs-card>
            <div slot="header">
              <h3>{{ $l.get("account.password-header") }}</h3>
            </div>
            <div>
              <vs-alert :active="isPasswordUpdateSuccess" color="success"
                >{{ $l.get("dictionary.success") }}!</vs-alert
              >
              <form @submit.prevent="onPasswordSubmit">
                <vs-input
                  v-model="currentPassword"
                  :danger="isCurrentPasswordInvalid"
                  :danger-text="$l.get('errors.input-required', 'capitalize')"
                  type="password"
                  :label-placeholder="
                    $l.get('account.current-password', 'capitalize')
                  "
                />
                <vs-input
                  v-model="newPassword"
                  :danger="isNewPasswordInvalid"
                  :danger-text="$l.get('errors.input-required', 'capitalize')"
                  type="password"
                  :label-placeholder="
                    $l.get('account.new-password', 'capitalize')
                  "
                />
                <vs-input
                  v-model="newPasswordConfirm"
                  :danger="isNewPasswordConfirmInvalid"
                  :danger-text="$l.get('errors.input-required', 'capitalize')"
                  type="password"
                  :label-placeholder="
                    $l.get('account.new-password-confirm', 'capitalize')
                  "
                />
                <input
                  type="submit"
                  style="position: absolute; left: -9999px; width: 1px; height: 1px;"
                  tabindex="-1"
                />
              </form>
              <div
                style="margin-top: 25px; margin-bottom: 10px; text-align: right"
              >
                <vs-button
                  color="primary"
                  type="filled"
                  @click="onPasswordSubmit()"
                  >{{ $l.get("dictionary.submit") }}</vs-button
                >
              </div>
            </div>
          </vs-card>
        </vs-col>
      </vs-row>
      <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="7">
          <vs-card>
            <div slot="header">
              <h3 v-if="isMainProfile">
                {{ $l.get("account.account-delete-header") }}
              </h3>
              <h3 v-else>
                {{ $l.get("account.account-delete-action") }} "{{
                  data.session.user.name
                }}"
              </h3>
            </div>
            <div>
              <vs-button
                color="danger"
                type="filled"
                @click="onAccountDeleteClick()"
                >{{ $l.get("account.account-delete-action") }}</vs-button
              >
            </div>
          </vs-card>
        </vs-col>
      </vs-row>
    </div>
    <vs-prompt
      color="danger"
      @accept="onAccountDeleteSubmit()"
      @cancel="setAsDeletePromptClosed()"
      :acceptText="$l.get('account.account-delete-action')"
      :cancelText="$l.get('dictionary.cancel')"
      :title="$l.get('dictionary.watch-out')"
      :is-valid="!isDeletePasswordInvalid"
      :active.sync="isDeletePromptOpen"
    >
      <div class="delete-warning">
        <p>{{ $l.get("account.account-delete-body") }}</p>
        <div style="margin-top: 10px"></div>
        <vs-input
          v-model="deletePassword"
          :danger="isNewPasswordConfirmInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          type="password"
          :label-placeholder="$l.get('dictionary.password', 'capitalize')"
        />
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import SubmitPasswordUpdateInteractor from "@/business/account/submit-password-update";
import SelectAccountDeleteInteractor from "@/business/account/select-account-delete";
import SubmitAccountDeleteInteractor from "@/business/account/submit-account-delete";
import SubmitAccountInteractor from "@/business/account/submit-account";
import InitAccountInteractor from "@/business/account/init-account";
import AccountScreenController from "@/adapters/controllers/screen-account";

import {
  UserAccountUpdate,
  UserPasswordUpdate,
  UserAccountDelete
} from "@/domain/users/models";

import Loader from "@/application/components/Loader";

export default {
  screen_name: "account",
  components: { Loader },
  data() {
    return {
      controller: null,
      fullName: "",
      language: "en-US",
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      deletePassword: "",
      isNameInvalid: false,
      isCurrentPasswordInvalid: false,
      isNewPasswordInvalid: false,
      isNewPasswordConfirmInvalid: false,
      isAccountUpdateSuccess: false,
      isPasswordUpdateSuccess: false,
      isDeletePromptOpen: false,
      isDeletePasswordInvalid: false,
      interactors: {
        submitPasswordUpdate: null,
        selectAccountDelete: null,
        submitAccountDelete: null,
        submitAccount: null,
        initAccount: null
      },
      data: null,
      isLoading: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(AccountScreenController);

    //{ INTERACTORS
    this.interactors.initAccount = this.$injector.get(InitAccountInteractor);
    this.interactors.submitAccount = this.$injector.get(
      SubmitAccountInteractor
    );
    this.interactors.submitAccountDelete = this.$injector.get(
      SubmitAccountDeleteInteractor
    );
    this.interactors.selectAccountDelete = this.$injector.get(
      SelectAccountDeleteInteractor
    );
    this.interactors.submitPasswordUpdate = this.$injector.get(
      SubmitPasswordUpdateInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
    this.controller.setAsNameInvalid = this.setAsNameInvalid;
    this.controller.setAsCurrentPasswordInvalid = this.setAsCurrentPasswordInvalid;
    this.controller.setAsNewPasswordInvalid = this.setAsNewPasswordInvalid;
    this.controller.setAsNewPasswordConfirmInvalid = this.setAsNewPasswordConfirmInvalid;
    this.controller.setAsAccountUpdateSuccess = this.setAsAccountUpdateSuccess;
    this.controller.setAsPasswordUpdateSuccess = this.setAsPasswordUpdateSuccess;
    this.controller.setAsDeletePasswordInvalid = this.setAsDeletePasswordInvalid;
    this.controller.setAsDeletePromptOpened = this.setAsDeletePromptOpened;
    this.controller.setAsDeletePromptClosed = this.setAsDeletePromptClosed;
    this.controller.resetFormErrors = this.resetFormErrors;
  },
  mounted() {
    this.interactors.initAccount.handle();
  },
  computed: {
    isMainProfile() {
      return (
        this.data && this.data.credential.user.id == this.data.session.user.id
      );
    }
  },
  methods: {
    setData(data) {
      this.data = data;
      this.fullName = this.data.session.user.name;
      this.language = this.data.session.user.language;
      this.currentPassword = "";
      this.newPassword = "";
      this.newPasswordConfirm = "";
      this.isAccountUpdateSuccess = false;
      this.isPasswordUpdateSuccess = false;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    setAsNameInvalid() {
      this.isNameInvalid = true;
    },
    setAsCurrentPasswordInvalid() {
      this.isCurrentPasswordInvalid = true;
    },
    setAsNewPasswordInvalid() {
      this.isNewPasswordInvalid = true;
    },
    setAsNewPasswordConfirmInvalid() {
      this.isNewPasswordConfirmInvalid = true;
    },
    setAsAccountUpdateSuccess() {
      this.isAccountUpdateSuccess = true;
    },
    setAsPasswordUpdateSuccess() {
      this.isPasswordUpdateSuccess = true;
    },
    setAsDeletePasswordInvalid() {
      this.isDeletePasswordInvalid = true;
    },
    resetFormErrors() {
      this.isNameInvalid = false;
      this.isCurrentPasswordInvalid = false;
      this.isNewPasswordInvalid = false;
      this.isNewPasswordConfirmInvalid = false;
      this.isDeletePasswordInvalid = false;
      this.isAccountUpdateSuccess = false;
      this.isPasswordUpdateSuccess = false;
    },
    onAccountSubmit() {
      this.interactors.submitAccount.handle(
        new UserAccountUpdate(this.fullName, this.language)
      );
    },
    onPasswordSubmit() {
      this.interactors.submitPasswordUpdate.handle(
        new UserPasswordUpdate(
          this.data.credential.username,
          this.currentPassword,
          this.newPassword,
          this.newPasswordConfirm
        )
      );
    },
    setAsDeletePromptOpened() {
      this.isDeletePromptOpen = true;
    },
    setAsDeletePromptClosed() {
      this.isDeletePromptOpen = false;
    },
    onAccountDeleteClick() {
      this.setAsDeletePromptOpened();
    },
    onAccountDeleteSubmit() {
      this.interactors.submitAccountDelete.handle(
        new UserAccountDelete(
          this.data.credential.username,
          this.deletePassword
        )
      );
    }
  }
};
</script>

<style lang="scss">
.account .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}
.account .vs-button {
  width: 100% !important;
}
.header-text {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
}
.inputs .vs-input {
  margin: 15px;
}

.delete-warning {
  text-align: center;
}
.delete-warning .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}
</style>
